export const Roles = {
  AuditPartner: "Audit Partner/RI",
  AuditManager: "Audit Manager",
  Auditor: "Auditor",
  Delegate: "Delegate",
}

export const ButtonActions = {
  Manage: "Manage",
  View: "View",
}

export const TeamMemberActions = {
  Send: "Send",
  Resend: "Resend",
  Remove: "Remove",
  EditRole: "EditRole",
}

export const EngagementActions = {
  Manage: "manage",
  View: "view",
}

export const ConfirmationHistory = {
  viewNotes: "viewNotes",
  resendNotes: "resendNotes",
  pending: "Pending",
  ethics: "Ethics",
  independence: "Independence",
}

export const ConfirmationState = {
  Confirmed: "Confirmed",
  No: "No",
  Pending: "Pending"
}

export const ExtensionToMIME = {
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip',
  png: 'image/png',
};

export const AuditTypes = {
  standAloneWithAffiliates: 1,
  // standAlone: 2,
  groupAudit: 3,
  statutory: 4,
}